
  import { defineComponent, ref } from "vue"
  import { SingleResourceDoc } from '@/models/jsonapi'
  import SettingsFormEdit from '@/components/shared/SettingsFormEdit.vue'

  interface Props {}

  export default defineComponent({
    components: {
      SettingsFormEdit
    },
    props: {},
    setup(props: Props) {
      return {

      }
    }
  })
