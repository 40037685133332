
  import { defineComponent, reactive, ref, computed, getCurrentInstance } from "vue"
  import FormStepper from "@/components/shared/FormStepper.vue"
  import { Datetime } from 'vue-datetime'
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"

  export default defineComponent({
    components: {
      FormStepper,
      SchemaForm
    },
    setup(_) {
      const root = getCurrentInstance().proxy
      const settingsKey = ref(0)
      const userId = computed<string>(() => root.$store.state.users.currentUser?.data?.id)
      const userSettings = computed<{[key:string]: any}>(() => {
        const settings = root.$store.state.users.currentUser?.data?.attributes?.settings
        settingsKey.value += 1
        if (settings) {
          if (!settings.dark) {
            settings.dark = localStorage.getItem('theme') === 'dark'
          }
          return settings
        } else {
          return {
            dark: localStorage.getItem('theme') === 'dark'
          }
        }
      })

      root.$store.dispatch('users/get_current_user')

      const formSchema = computed(() => {
        const properties = {}
        Object.entries(userSettings.value).forEach(entry => {
          const [key, value] = entry
          const typedValue = typeof(value) === 'number' ? 
            Number.isInteger(value) ? 'integer' : 'number'
            : typeof(value)
          properties[key] = { type: typedValue, title: key }
        })
        return {
          $schema: "https://json-schema.org/draft/2019-09/schema",
          $id: "https://crm.pflegehilfe-senioren.de/settings-model.json",
          type: "object",
          properties: properties,
          required: []
        }
      })

      const uiSchema = computed(() => {
        const properties = {}
        Object.entries(userSettings.value).forEach(entry => {
          const [key, value] = entry
          properties[key] = {  
            title: key,
            $ref: `https://crm.pflegehilfe-senioren.de/settings-model.json#/properties/${key}`,
            "ui:type": typeof value === 'boolean' ? "checkbox" : undefined,
            "ui:options": {
              type: typeof(value) === 'number' ? Number.isInteger(value) ? 'integer' : 'number' : undefined
            }
          }
        })
        return {
          $schema: "https://json-schema.org/draft/2019-09/schema",
          $id: "https://crm.pflegehilfe-senioren.de/settings-view.json",
          $vocabulary: {
            "https://json-schema.org/draft/2019-09/vocab/core": true,
            "https://crm.pflegehilfe-senioren.de/ui-vocab": true
          },
          "ui:type": "form",
          "ui:widget": "b-row",
          type: "object",
          properties: properties
        }
      })

      const formOptions = ref()

    const updatedForm = (formValue) => {
      if (formValue.dark) {
        localStorage.setItem('theme', 'dark')
        document.documentElement.className = 'dark'
      } else {
        localStorage.setItem('theme', 'light')
        document.documentElement.className = 'light'
      }
    }

      return {
        settingsKey,
        userSettings,
        formSchema,
        uiSchema,
        formOptions,
        updatedForm
      }
    }
  })
